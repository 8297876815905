<template>
  <el-dialog
    top="80px"
    width="600px"
    class="package-form"
    title="Tạo package mới"
    :visible="visible"
    :close-on-click-modal="false"
    @close="handleClose"
    @opened="loadFirstType"
  >
    <el-form ref="form" :model="form" :rules="rules" label-position="top">
      <el-row :gutter="24">
        <el-col :lg="12">
          <el-form-item label="Từ ngày" prop="startDate">
            <el-date-picker
              v-model="form.startDate"
              type="date"
              placeholder="Chọn thời gian bắt đầu"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :lg="12">
          <el-form-item label="Đến ngày" prop="endDate">
            <el-date-picker
              v-model="form.endDate"
              type="date"
              placeholder="Chọn thời gian kết thúc"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="Chọn loại gói" prop="package_type">
        <el-select
          v-model="form.package_type"
          placeholder="Chọn loại gói"
          disabled
        >
          <el-option value="tour" label="Gói tham quan thực tế"></el-option>
          <el-option
            value="vr_tour"
            label="Gói tham quan thực tế ảo VR3D"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Tên gói" prop="name">
        <el-input
          v-model="form.name"
          :disabled="callingAPI"
          placeholder="Nhập tên gói"
        ></el-input>
      </el-form-item>
      <div v-if="form.package_type === 'tour'">
        <el-form-item label="Giá vé người lớn (VNĐ)" prop="adultPrice">
          <el-input
            v-model.number="form.adultPrice"
            :disabled="callingAPI"
            placeholder="Giá vé người lớn"
          ></el-input>
        </el-form-item>
        <el-form-item label="Giá vé trẻ em (VNĐ)" prop="infantPrice">
          <el-input
            v-model.number="form.infantPrice"
            :disabled="callingAPI"
            placeholder="Giá vé trẻ em"
          ></el-input>
        </el-form-item>
      </div>
      <el-form-item v-else label="Giá toàn bộ gói (VNĐ)" prop="amount">
        <el-input
          v-model.number="form.amount"
          :disabled="callingAPI"
          placeholder="Giá toàn bộ gói"
        ></el-input>
      </el-form-item>
      <el-form-item label="Giảm giá (%)" prop="discount">
        <el-input
          v-model.number="form.discount"
          :disabled="callingAPI"
          placeholder="Nhập giá trị % giảm"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button :disabled="callingAPI" @click="handleClose">Hủy</el-button>
      <el-button
        type="primary"
        :loading="callingAPI"
        @click="handleCreatePackage"
      >Tạo mới</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { createPackage } from '@/services/package'
import dayjs from 'dayjs'
export default {
  name: 'PackageForm',
  props: {
    visible: Boolean,
    packageType: String
  },
  data() {
    return {
      callingAPI: false,
      form: {
        startDate: '',
        endDate: '',
        package_type: '',
        name: '',
        adultPrice: 0,
        infantPrice: 0,
        amount: 0,
        discount: 0
      },
      rules: {
        startDate: [
          { required: true, message: 'Ngày bắt đầu không được để trống', trigger: ['blur', 'change'] }
        ],
        endDate: [
          { required: true, message: 'Ngày kết thúc không được để trống', trigger: ['blur', 'change'] }
        ],
        package_type: [
          { required: true, message: 'Loại gói không được để trống', trigger: ['blur', 'change'] }
        ],
        name: [
          { required: true, message: 'Tên gói không được để trống', trigger: ['blur', 'change'] }
        ],
        discount: [
          { required: true, message: 'Giá trị không được để trống', trigger: ['blur', 'change'] },
          { type: 'number', message: 'Giá trị phải là số', trigger: ['blur', 'change'] },
          { type: 'number', min: 0, message: 'Giá trị nhỏ nhất là 0', trigger: ['blur', 'change'] },
          { type: 'number', max: 100, message: 'Giá trị lơn nhất là 100', trigger: ['blur', 'change'] }
        ],
        adultPrice: [
          { required: true, message: 'Giá trị không được để trống', trigger: ['blur', 'change'] },
          { type: 'number', message: 'Giá trị phải là số', trigger: ['blur', 'change'] }
        ],
        infantPrice: [
          { required: true, message: 'Giá trị không được để trống', trigger: ['blur', 'change'] },
          { type: 'number', message: 'Giá trị phải là số', trigger: ['blur', 'change'] }
        ],
        amount: [
          { required: true, message: 'Giá trị không được để trống', trigger: ['blur', 'change'] },
          { type: 'number', message: 'Giá trị phải là số', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['language'])
  },
  methods: {
    loadFirstType() {
      this.form.package_type = this.packageType
    },
    handleCreatePackage() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.callingAPI = true
          const data = {
            summary: this.form.name,
            description: '',
            discount: this.form.discount,
            start_time: dayjs(this.form.startDate).format('YYYY-MM-DDT00:00:00'),
            end_time: dayjs(this.form.endDate).format('YYYY-MM-DDT00:00:00'),
            package_type: this.form.package_type,
            locale: this.language,
            is_private: false
          }
          if (this.form.package_type === 'tour') {
            data.unit_prices = [
              { amount: this.form.adultPrice, price_type: 'adult', is_activated: true },
              { amount: this.form.infantPrice, price_type: 'infant', is_activated: true }
            ]
          } else {
            data.unit_prices = [
              { amount: this.form.amount, price_type: 'tour', is_activated: true }
            ]
          }
          await createPackage(data).then(() => {
            this.$notify.success({
              title: 'Thành công',
              message: 'Tạo mới gói thành công'
            })
            this.$refs.form.resetFields()
            this.$emit('reload')
            this.callingAPI = false
          }).catch(() => {
            this.$notify.error({
              title: 'Thất bại',
              message: 'Tạo mới gói thất bại'
            })
            this.callingAPI = false
          })
        }
      })
    },

    handleClose() {
      this.$refs.form.resetFields()
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped></style>
<style lang="scss">
</style>
